<template>
    <div>
        <van-nav-bar title="门店首页" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="shop-header">
            <div class="shop-name">
                {{ shop.name }}
            </div>
            <div class="shop-icon">
                <van-icon name="add-o" size="20" @click="addGoods" />
                <van-icon name="more-o" size="20" @click="more" />
            </div>
        </div>
        <div class="shop-tabs">
            <van-tabs v-model="active"  @click="changeTab" line-height="2" sticky swipeable>
                <van-tab title="待发布">
                    <div class="goods-content">
                        <GoodsItem  :c_type="false" tab_type="wait_release" :shop_id="shop.id"/>
                    </div>
                </van-tab>
                <van-tab title="审核中">
                    <div class="goods-content">
                        <GoodsItem  :c_type="false" tab_type="review" :shop_id="shop.id"/>
                    </div>
                </van-tab>
                <van-tab title="已上线">
                    <div class="goods-content">
                        <GoodsItem   tab_type="online" :shop_id="shop.id"/>
                    </div>
                </van-tab>
                <van-tab title="已下线">
                    <div class="goods-content">
                        <GoodsItem   tab_type="outline" :shop_id="shop.id"/>
                    </div>
                </van-tab>
            </van-tabs>
        </div>


        <!-- 门店相关操作右侧弹出层 -->
        <van-popup v-model="show_right" position="right" :style="{ width: '35%', height: '100%' }">
        <div class="popup-content">
            <div class="popup-item" @click="toFans">
                <van-icon name="friends-o" size="15"/>
                <div class="popup-name">粉丝列表</div>
            </div>

            <div class="popup-item" @click="toMessage">
                <van-icon name="coupon-o" size="15"/>
                <div class="popup-name">回复列表</div>
            </div>

            <div class="popup-item" @click="toStatistics">
                <van-icon name="chart-trending-o" size="15"/>
                <div class="popup-name">数据统计</div>
            </div>
            
        </div>
        </van-popup>
    </div>
    
</template>
<script>

import GoodsItem from '@/components/GoodsItem.vue';
export default {
    name: "ShopDetailPage",
    components: {
        GoodsItem
    },
    data() {
        return {
            shop: {
                id: "",
                name: ""
            },
            active: 2,
            show_right: false
        }
    },
    methods: {
        onClickLeft() {
            this.$router.back();
        },
        changeTab(e){
            this.active = e
        },
        addGoods(){
            this.$router.push({
                path: "/addgoods",
                query: {
                    id: this.shop.id
                }
            })
        },
        more(){
            this.show_right = true
        },
        toStatistics(){
            this.$toast.fail("暂未开放");
            // this.$router.push({
            //     path: "/statistics",
            //     params: {
            //         id: this.shop.id
            //     }
            // })
        },
        toFans(){
            this.$router.push({
                path: "/fans",
                query: {
                    id: this.shop.id
                }
            })
        },
        toMessage(){
            this.$router.push({
                path: "/answer",
                query: {
                    id: this.shop.id
                }
            })
        }
    },
    created() {
        
        this.shop.name = this.$route.query.name
        this.shop.id = this.$route.query.id
        
    },
    beforeRouteLeave (to, from, next) {
  // 去这些界面我需要缓存
    const routerArr = ['/goodsdetail']
    if (!routerArr.includes(to.path)) {
    // 不在缓存列表中，从cachedViews缓存列表中移除
        this.$store.commit('DEL_CACHED_VIEW', from)
    }
    
    next()
  },
  beforeDestroy() {
    // 离开页面时清除缓存
    console.log('销毁')
  }

}
</script>
<style scoped>
.shop-header {
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
}


.shop-name {
    font-weight: bold;
    margin-left: 10px;
    font-size: 14px;
    color: rgb(128, 128, 128);
}

.shop-icon {
    margin-right: 10px;
}

.shop-icon .van-icon {
    margin-left: 18px;
}
.shop-tabs{
    margin-top: 5px;
}
.van-tabs__line{
    background-color: #1989fa;
}
.popup-content{
    padding-top: 20px;
}
.popup-item{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.popup-name{
    margin-left: 10px;
    font-size: 14px;
    color: gray;
}
/* .goods-content{
    margin-top: 10px;
} */
</style>