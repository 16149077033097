<template lang="">
    <div class="login">
        <van-nav-bar title="商户登录"/>
        <div class="logo">
          <img src="@/assets/image/mjjr1.webp" alt="">
          
        </div>
        <van-form>
          <van-field
            v-model="username"
            name="用户名"
            label="用户名"
            placeholder="手机号"
            
          />
          <van-field
            v-model="password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            
          />
          <!-- 注册 -->
          
          <div class="register">
              <router-link to="/register">注册</router-link>
          </div>
          
          <div style="margin: 16px;">
            <van-button round block type="info" @click="login">登录</van-button>
          </div>
        </van-form>
    </div>
</template>
<script>
import md5 from "md5.js";
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    login() {
      if (!this.username || !this.password) {
        this.$toast.fail("请填写完整信息");
        return;
      }
      // 对密码进行md5加密
      console.log(this.password);
      // 创建一个 md5 实例
      const hash = new md5();

      // 更新数据
      hash.update(this.password);

      // 获取十六进制格式的哈希值
      const password_md5 = hash.digest('hex');
      // const password_md5 = md5(this.password);
      this.$toast.loading({
        message: "登录中...",
        forbidClick: true,
      });
      this.$http
        .post("/mingguang/login/", {
          username: this.username,
          password: password_md5,
        })
        .then((res) => {
          
          if (res.data.code !== 200) {
            this.$toast.fail(res.data.msg);
            return;
          }else {
            //将token存入localStorage
            localStorage.setItem("token", res.data.token);
            this.$toast.clear();
            this.$toast.success("登录成功");
            this.$router.replace("/shoplist");
          }
          
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
  },
};
</script>
<style scoped>
.logo {
  text-align: center;
}
.register {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}
img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-top: 120px;
  margin-bottom: 120px;
}
</style>