import Vue from "vue";
import { Button,NavBar,Form,Field,Icon,Popup,Uploader,Tab, Tabs,Card,Toast,Tag,DropdownMenu, DropdownItem ,List,Collapse, CollapseItem ,Image} from "vant";
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Uploader);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Card);
Vue.use(Toast);
Vue.use(Tag);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(List);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Image);