<template>
  <div class="hello">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-card
        :title="item.title"
        :thumb="item.thumb"
        v-for="item in goods_info"
        :key="item.id"
        @click="toDetail(item.id)"
      >
        <template #tags>
          <van-tag
            color="#ffe1e1"
            text-color="#ad0000"
            :style="{ 'margin-right': '3px' }"
            v-for="(tag_, index_) in item.tags"
            :key="index_"
          >
            {{ tag_ }}
          </van-tag>
        </template>

        <template #price>
          <div class="price">
            <span>￥</span>
            <span>{{ item.price }}</span>
          </div>
        </template>

        <template #origin-price>
          <div>
            <span>￥</span>
            <span>{{ item.origin_price }}</span>
          </div>
        </template>

        <template #bottom>
          <div class="card-bottom">
            <div class="time">
              <span class="time_text">{{ item.time }}</span>
            </div>

            <div class="fucus">
              <div class="fav" v-if="c_type">
                <van-icon name="like" size="13" color="#ee0a24" />
                <span class="fav-num">{{ item.fav }}</span>
              </div>

              <div class="look" v-if="c_type">
                <van-icon name="eye" size="13" color="#c5c5c5" />
                <span class="look-num">{{ item.look }}</span>
              </div>

              <div class="deal">
                <van-icon name="weapp-nav" size="13" @click.stop="deal(item)" />
              </div>
            </div>
          </div>
        </template>

        <template #tag v-if="tab_type === 'review'">
          <van-tag
            mark
            type="primary"
            color="red"
            v-if="item.review_status === '未通过'"
            @click.stop="show_why(item.review_text)"
            >未通过</van-tag
          >
          <van-tag mark type="primary" color="blue" v-else>待审核</van-tag>
        </template>
      </van-card>
    </van-list>

    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '20%' }"
    >
      <div class="popup-content">
        <div class="popup-item" @click="change_goods_status">{{ status }}</div>
        <div class="popup-item" @click="edit" v-if="tab_type === 'review'">
          编辑
        </div>
        <div class="popup-item" @click="del" v-if="tab_type !== 'review'">
          删除
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "GoodsItem",
  data() {
    return {
      loading: false,
      finished: false,
      show: false,
      who_show_goods: {},
      page: 1,
      goods_info: [
        // {
        //   id: 1,
        //   title: "商品标题",
        //   thumb: "https://img01.yzcdn.cn/vant/leaf.jpg",
        //   price: "100",
        //   origin_price: "200",
        //   tags: ["换季促销", "特价"],
        //   time: "08-10 12:23",
        //   fav: "234",
        //   look: "456",
        // },
      ],
      //定义状态映射关系（该状态的下一步状态）
      status_map_reverse: {
        wait_release: "review",
        review: "wait_release",
        online: "offline",
        offline: "online",
      },
    };
  },
  computed: {
    // 根据tab_type的值来显示不同的状态
    status() {
      if (this.tab_type === "review") {
        return "撤回";
      } else if (this.tab_type === "wait_release") {
        return "发布";
      } else if (this.tab_type === "online") {
        return "下线";
      } else {
        return "重新上线";
      }
    },
  },
  props: {
    c_type: {
      type: Boolean,
      default: true,
    },
    tab_type: {
      type: String,
      required: true,
    },
    shop_id: {
      type: String,
      required: true,
    },
  },
  methods: {
    onLoad() {
      // 发起请求获取数据
      this.$http
        .get("/mingguang/get_goods_list", {
          params: {
            status: this.tab_type,
            page: this.page,
            shop_id: this.shop_id,
          },
        })
        .then((res) => {
          // console.log(res);
          if (res.data.code === 200) {
            //判断是否还有数据
            if (res.data.data.length < 15) {
              this.goods_info = [...this.goods_info, ...res.data.data];
              this.loading = false;
              this.finished = true;
            } else {
              // this.goods_info.push(res.data.data);
              this.goods_info = [...this.goods_info, ...res.data.data];
              this.loading = false;
              this.page++;
            }
          } else {
            this.$toast.fail("服务异常");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    show_why(text) {
      this.$dialog.alert({
        message: text,
      }).then(() => {
        // on close
      });
    },
    deal(goods) {
      this.who_show_goods = goods;
      this.show = true;
    },
    change_goods_status() {
      // 发送请求,更新状态
      this.$http
        .post("/mingguang/update_goods_status/", {
          id: this.who_show_goods.id,
          status: this.status_map_reverse[this.tab_type],
        })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.$toast.success("操作成功");
            this.show = false;
            this.$emit(
              "change_status",
              this.who_show_goods,
              this.status_map_reverse[this.tab_type]
            );
            // 更新列表
            this.goods_info = this.goods_info.filter(
              (item) => item.id !== this.who_show_goods.id
            );
          }
        });
    },
    edit() {
      this.$router.push({
        path: "/editgoods",
        query: {
          id: this.who_show_goods.id,
        },
      });
    },
    del() {
      this.$http
        .post("/mingguang/del", {
          id: this.who_show_goods.id,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.$toast.success("删除成功");
            this.show = false;
            // 更新列表
            this.goods_info = this.goods_info.filter(
              (item) => item.id !== this.who_show_id
            );
          }
        });
    },
    // 点击整个card的点击事件
    toDetail(id) {
      this.$router.push({
        path: "/goodsdetail",
        query: {
          id: id,
        },
      });
    },
  },
  mounted() {
    // 设置监听事件，监听组件中item的状态发生了变化后的gooss_info对应的变化
    this.$bus.$on("change_status", (goods, status) => {
      if (this.tab_type === status) {
        // 更新列表,将goods对象添加到goods_info中,放在列表的顶部
        this.goods_info.unshift(goods);
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  margin: 10px;
}
.van-card {
  border-radius: 5px;
}
.van-card__title {
  margin-bottom: 3px;
}
.fucus {
  display: flex;
}
.look {
  margin-left: 10px;
}
.deal {
  margin-left: 10px;
  padding-left: 10px;
}

.fav-num {
  margin-left: 2px;
}

.look-num {
  margin-left: 2px;
}
.price {
  font-size: 16px;
  color: orangered;
}
.time {
  font-size: 12px;
  color: #999;
}
.card-bottom {
  display: flex;
  justify-content: space-between;
}

.popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  align-items: center;
}
.popup-item {
  font-size: 15px;
  color: #000;
  height: 30%;
  display: flex;
  align-items: center;
}
</style>
