// 引入 Vue 和 VueRouter  
import Vue from 'vue';  
import Router from 'vue-router';  
  
// 引入各个组件  
// import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage'; 
import ShopListPage from '../pages/ShopListPage';
import AddShopPage from '../pages/AddShopPage';
import ShopDetailPage from '../pages/ShopDetailPage';
import AddGoodsPage from '../pages/AddGoodsPage';
import GoodsDetailPage from '../pages/GoodsDetailPage';
import FansPage from '../pages/FansPage';
import AnswerListPage from '../pages/AnswerListPage';
import AnswerDetailPage from '../pages/AnswerDetailPage';
import EditShopPage from '../pages/EditShopPage';
import EditGoodsPage from '../pages/EditGoodsPage';


// 告诉 Vue 使用 VueRouter  
Vue.use(Router);  
  
// 定义路由  
// 每个路由应该映射一个组件。 其中"component" 可以是  
// 通过 Vue.extend() 创建的组件构造器，  
// 或者，只是一个组件配置对象。  
// 我们晚点再讨论嵌套路由。  
const routes = [  
  {  
    path: '/',  
    name: 'LoginPage',  
    component: LoginPage  
  },
  {  
    path: '/login',  
    name: 'LoginPage',  
    component: LoginPage  
  },
  {  
    path: '/register',  
    name: 'RegisterPage',  
    component: RegisterPage  
  },
  {  
    path: '/shoplist',  
    name: 'ShopListPage',  
    component: ShopListPage  
  },
  {  
    path: '/addshop',  
    name: 'AddShopPage',  
    component: AddShopPage  
  },
  {  
    path: '/shopdetail',  
    name: 'ShopDetailPage',  
    component: ShopDetailPage,
    meta: { keepAlive: true },
  },
  {  
    path: '/addgoods',  
    name: 'AddGoodsPage',  
    component: AddGoodsPage  
  },
  {  
    path: '/goodsdetail',  
    name: 'GoodsDetailPage',  
    component: GoodsDetailPage  
  },
  {  
    path: '/fans',  
    name: 'FansPage',  
    component: FansPage  
  },
  {  
    path: '/answer',  
    name: 'AnswerPage',  
    component: AnswerListPage  
  },
  {  
    path: '/answerdetail',  
    name: 'AnswerDetailPage',  
    component: AnswerDetailPage  
  },
  {  
    path: '/editshop',  
    name: 'EditShopPage',  
    component: EditShopPage  
  },
  {  
    path: '/editgoods',  
    name: 'EditGoodsPage',  
    component: EditGoodsPage  
  },
];  
  
// 创建 router 实例，然后传 `routes` 配置  
// 你还可以传别的配置参数, 不过先这么简单着吧。  
const router = new Router({    
  routes // （缩写）相当于 routes: routes  
});


router.beforeEach((to, from, next) => {  
  if (to.path === '/login' || to.path === '/register') {  
    next();  
  } else {  
    let token = localStorage.getItem('token');  
    if (token === null || token === '') {  
      next('/login');  
    } else {  
      next();  
    }  
  }  
})
  
// 导出 router，以便在 main.js 文件中使用  
export default router;