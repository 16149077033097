<template>
  <div>
    <!-- 页头 -->
    <van-nav-bar title="门店列表">
      <template #right>
        <van-icon name="add-o" size="18" @click="openAddShop" />
      </template>
    </van-nav-bar>
    <!-- 门店列表 -->
    <div class="shop-list">
      <div
        class="shop-item"
        v-for="item in shops"
        :key="item.id"
        @click="toShopDetail(item)"
      >
        <div class="shop-img">
          <van-image :src="item.pic_door" radius="5" height="100" width="100"/>
        </div>
        <div class="shop_info">
          <div class="shop-name">{{ item.name }}</div>
          <div class="shop-per">{{ item.per }}</div>
          <div class="shop-address">{{ item.address }}</div>
          <div class="footer">
            <div :class="item.status === '营业中' ? 'status1' : 'status2'">
              {{ item.status }}
            </div>
            <!-- 点击弹出操作弹窗（事件不传递） -->
            <van-icon name="ellipsis" size="18" @click.stop="more(item)" />
          </div>
        </div>
      </div>
    </div>

    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div class="popup-content">
        <div class="popup-item" @click="change_status">{{ shop_deal }}</div>
        <div class="popup-item" @click="edit_shop">修改门店</div>
        <div class="popup-item" @click="del_shop">删除门店</div>
      </div>
    </van-popup>
  </div>
</template>


<script>
// import { del } from "vue";
import { Dialog } from "vant"
export default {
  data() {
    return {
      phone: "",
      shops: [],
      show: false,
      shop_deal: "",
      who_show_shop: {},
    };
  },
  methods: {
    toShopDetail(item) {
      // console.log(item);
      this.$router.push({
        path: "/shopdetail",
        query: {
          id: item.id,
          name: item.name,
        },
      });
    },
    more(item) {
      this.show = true;
      if (item.status === "营业中") {
        this.shop_deal = "暂停营业"
      } else {
        this.shop_deal = "开始营业"
      }
      this.who_show_shop = item
    },
    openAddShop() {
      this.$router.push({
        path: "/addshop",
      });
    },
    change_status() {
      this.$http
        .post("/mingguang/change_shop_status/", {
          id: this.who_show_shop.id,
          status: this.who_show_shop.status,
        })
        .then((res) => {
          console.log(res)
          if (res.data.code !== 200) {
            this.$toast.fail(res.data.msg)
            this.show = false
          } else {
            this.$toast.success(res.data.msg)
            this.show = false
            window.location.reload()
          }
        }).catch(() => {
          // on cancel
          this.$toast.fail("服务异常")
        })
    },
    edit_shop() {
      this.$router.push({
        path: "/editshop",
        query: {
          id: this.who_show_shop.id,
        },
      });
    },
    del_shop() {
      //弹框确认
      Dialog.confirm({
        title: "删除确认",
        message: "确定删除该门店吗？",
      })
        .then(() => {
          // on confirm
          this.$http
            .post("/mingguang/delete_shop/", {
              id: this.who_show_shop.id,
            })
            .then((res) => {
              console.log(res);
              if (res.data.code !== 200) {
                this.$toast.fail(res.data.msg)
                this.show = false
              } else {
                this.$toast.success(res.data.msg)
                this.show = false
                window.location.reload()
              }
            });
        })
        .catch(() => {
          // on cancel
          // this.$toast.fail("服务异常")
        });
    },
  },
  created() {
    //请求数据
    this.$http
      .get("/mingguang/get_shop_list")
      .then((res) => {
        // console.log(res);
        if (res.data.code !== 200) {
          this.$toast.fail(res.data.msg)
          return
        } else {
          this.shops = res.data.data
          this.phone = this.data.phone
        }
      })
      .catch((err) => {
        console.log(err)
      });
  },
};
</script>
<style scoped>
.shop-item {
  height: 100px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px solid #eee;
  margin: 10px;
}
.shop-img{
  width: 100px;
}
/* .shop-item img {
  width: 24%;
  height: 90px;
  border-radius: 5px;
} */

.shop_info {
  margin-left: 20px;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.shop-name {
  font-size: 16px;
  font-weight: bold;
}
.shop-per {
  font-size: small;
  color: #000;
  margin-top: 5px;
}

.shop-address {
  font-size: small;
  color: rgb(128, 128, 128);
  margin-top: 5px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  align-items: center;
}
.status1 {
  font-size: small;
  color: #07c160;
}
.status2 {
  color: red;
  font-size: small;
}
.popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  align-items: center;
}
.popup-item {
  font-size: 15px;
  color: #000;
  height: 30%;
  display: flex;
  align-items: center;
}

.popup-item:nth-last-child(1) {
  color: red;
}
</style>