<template>
  <div>
    <van-nav-bar
      title="修改商品"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- 注册表单 -->
    <div class="add_goods_page">
      <van-form >
        <van-field
          v-model="title"
          name="title"
          label="标题"
          placeholder="商品标题"
          :rules="[{ required: true }]"
        />

        <van-field
          v-model="detail"
          name="detail"
          label="描述"
          placeholder="商品描述"
          :rules="[{ required: true }]"
        />

        <van-field
          v-model="tags"
          name="tag"
          label="标签"
          placeholder="最多可以添加3个标签用逗号隔开"
          :rules="[{ required: true }]"
        />

        <van-field
          v-model="chart_org"
          name="chart_org"
          label="原价"
          placeholder="商品原价"
          :rules="[{ required: true }]"
        />

        <van-field
          v-model="chart"
          name="chart"
          label="现价"
          placeholder="当前价格"
          :rules="[{ required: true }]"
        />

        <!-- 商品分组 -->
        <div class="custom_text group">
          <div style="width: 87px">商品分组</div>
          <div style="width: auto">
            <van-dropdown-menu>
              <van-dropdown-item v-model="value1" :options="option1" />
            </van-dropdown-menu>
          </div>
        </div>

        <!-- 商品分类 -->
        <div class="custom_text group">
          <div style="width: 87px">商品分类</div>
          <div style="width: auto">
            <van-dropdown-menu>
              <van-dropdown-item v-model="value2" :options="option2" />
            </van-dropdown-menu>
          </div>
        </div>

        <!-- 商品图片 -->
        <div class="custom_text">商品图片</div>
        <div class="custom_pic">
          <van-uploader
            v-model="fileList"
            :after-read="afterRead1"
            :before-delete="beforeDelete1"
            :max-count="15"
            multiple
          />
        </div>

        <div style="margin: 16px">
          <van-button round block type="info" @click="submit">提 交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>


<script>
import client from "../utils/tools";
export default {
  data() {
    return {
      title: "",
      detail: "",
      tags: "",
      chart_org: "",
      chart: "",
      fileList: [],
      fileList_len: 0,
      value1: 0,
      value2: 0,
      option1: [
        { text: "选择分组", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      option2: [
        { text: "选择分类", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
    };
  },
  methods: {
    submit() {
      console.log(this.fileList.map((item) => item.url));
      //判断数据不为空
      if (
        this.title === "" ||
        this.detail === "" ||
        this.tag === "" ||
        this.chart_org === "" ||
        this.chart === "" ||
        this.fileList.length === 0
      ) {
        this.$toast.fail("请填写完整信息");
        return;
      }

      this.$http
        .post("/mingguang/editgoods", {
          id: this.$route.params.id,
          title: this.title,
          detail: this.detail,
          tag: this.tag,
          chart_org: this.chart_org,
          chart: this.chart,
          pic: this.fileList.map((item) => item.url),
          group: this.value1,
          category: this.value2,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.$toast.success("修改成功");
            this.$router.replace({
              path: "/goodslist",
            });
          } else {
            this.$toast.fail(res.data.msg);
          }
        })
        .catch((e) => {
          console.log(e);
          this.$toast.fail("服务异常");
        });
    },
    onClickLeft() {
      this.$router.back();
    },
    upload_image(file, fileList, index) {
      this.$set(fileList[index], "status", "uploading");
      this.$set(fileList[index], "message", "上传中...");
      const filePath = this.$filePath + Date.now() + "_" + file.file.name;
      client
        .put(filePath, file.file)
        .then((res) => {
          console.log(res);
          //上传成功,更新fileList1
          this.$set(fileList[index], "status", "done");
          this.$set(fileList[index], "url", res.url);
          this.$set(fileList[index], "message", "");
          console.log(this.fileList1);
        })
        .catch((e) => {
          // 上传失败,更新fileList1
          console.log(e);
          this.$set(fileList[index], "status", "failed");
          this.$set(fileList[index], "message", "上传失败");
        });
    },
    beforeDelete1() {
      this.fileList_len--;
      return true;
    },
    afterRead1(files) {
      if (files instanceof Array) {
        for (let i = 0; i < files.length; i++) {
          this.upload_image(files[i], this.fileList, i + this.fileList_len);
        }
      } else if (files instanceof Object) {
        this.upload_image(files, this.fileList, this.fileList_len);
      }
      this.fileList_len = this.fileList.length
    },
  },
  mounted() {
    this.$http
      .get("/mingguang/get_goods_detail", {
        params: {
          id: this.$route.query.id,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.title = res.data.data.title;
          this.detail = res.data.data.detail;
          this.tags = res.data.data.tags_edit;
          this.chart_org = res.data.data.chart_org;
          this.chart = res.data.data.price;
          this.fileList = res.data.data.pictures_edit;
          this.value1 = res.data.data.group;
          this.value2 = res.data.data.category;
        }
      })
  }
};
</script>
<style>
.custom_text {
  font-size: 14px;
  color: #646566;
  margin-left: 15px;
  margin-top: 20px;
}
.custom_pic {
  margin-left: 15px;
  margin-top: 20px;
}
.group {
  display: flex;
  align-items: center;
}
</style>