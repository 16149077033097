<template>
  <div>
    <van-nav-bar
      title="粉丝列表"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="(item, index) in list" :key="index" @click="onClick(item)" class="item">
        <div class="img">
          <img :src="item.avatar" alt="" />
        </div>

        <div class="name">
          {{ item.nick }}
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>

export default {
  data() {
    return {
      id: '',
      list: [],
      loading: false,
      finished: false,
      page: 1,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    onLoad() {
      // 发起请求获取数据
      this.$http
        .get("/mingguang/get_fav_list", {
          params: {
            page: this.page,
            id: this.id
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            //判断是否还有数据
          if (res.data.data.length < 15) {
            this.list = [...this.list, ...res.data.data]
            this.loading = false
            this.finished = true
          } else {
            this.list = [...this.list, ...res.data.data]
            this.loading = false
            this.page++
          }
          }else{
            this.$toast.fail("服务异常")
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  mounted() {
    this.id = this.$route.query.id
  }
};
</script>

<style scoped>
.item {
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    
}
.img{
    width: 50px;
    height: 50px;
}
.img img {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    border-radius: 25px;
}

.name{
    margin-left: 20px;
}
</style>