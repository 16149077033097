import Vue from 'vue'
import App from './App.vue'
import './utils/vant-ui'
import 'vant/lib/index.css';
import 'vant/lib/index.less';
import store from './store';

Vue.config.productionTip = false
import router from './router'; // 引入路由 
import axios from 'axios';  // 引入axios
axios.defaults.baseURL = 'http://zhiyiscience.cn'; // 设置axios请求的根路径
// axios.defaults.baseURL = 'http://localhost:8080'; // 设置axios请求的根路径
//编写axios的全局配置
axios.defaults.timeout = 5000;
//axios在访问前从localStorage中获取token,添加到消息头中，如果获取到的token不为空，则添加到消息头中，如果为空或null，则跳转到登录页面
axios.interceptors.request.use(config => {
  if (localStorage.getItem('token')) {
    config.headers.Authorization = localStorage.getItem('token');
  }else {
    router.push('/login');
  }
  return config;
});
//axios在响应后拦截，如果响应的状态码为401，则跳转到登录页面
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 返回 401 清除token信息并跳转到登录页面
          localStorage.removeItem('token');
          router.push({
            path: '/login'
          });
     }
    }
  }
)
Vue.prototype.$axios = axios;
Vue.prototype.$http = axios;
Vue.prototype.$filePath = 'mingjingjiaren/'

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this; // 创建事件总线
  }
}).$mount('#app')
