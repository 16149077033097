<template>
  <div>
    <van-nav-bar
      title="消息列表"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- 商品信息展示 -->
    <div class="img_text">
      <div class="img">
        <img :src="url" alt="" />
      </div>

      <div class="text">
        <p>{{ title }}</p>
        <p>￥{{ price }}</p>
        <p>{{ time }}</p>
      </div>
    </div>

    <!-- 消息展示 -->
    <div class="message">
      <van-tabs v-model="active">
        <van-tab title="待回复"  :dot="is_dot" >
            <MessageItem :goods_id="id" tab_type="wait_reply">
            </MessageItem>
        </van-tab>
        <van-tab title="已回复" >
          <MessageItem :goods_id="id" tab_type="replyed">
          </MessageItem>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import MessageItem from "../components/MessageItem.vue";
export default {
  data() {
    return {
      id: "",
      url: "",
      title: "",
      price: '',
      time: "",
      active: 0,
      is_dot: false,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
  },
  components: {MessageItem},
  mounted() {
    //获取路由参数
    this.id = this.$route.query.id;
    this.title = this.$route.query.title;
    this.price = this.$route.query.price;
    this.time = this.$route.query.time;
    this.url = this.$route.query.url;
  }
};
</script>
<style >
.img_text {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 10px;
  box-shadow: 0px 0px 5px rgb(212, 211, 211);
  border-radius: 5px;
}
.img {
  width: 100px;
  height: 100px;
}
.text {
  margin-left: 10px;
}

.text p:first-child {
  font-weight: bold;
  font-size: 16px;
  /* 超出显示省略号 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}
.text p:nth-child(2) {
  color: red;
}
.text p:nth-child(3) {
  color: rgb(128, 128, 128);
  font-size: small;
}
</style>