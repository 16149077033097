<template>
  
  <keep-alive :include="cachedViews">
    <router-view :key="key"></router-view> >
  </keep-alive>
  
</template>

<script>
// import Home from './pages/Home.vue'
// import { mapState } from 'vuex';
export default {
  name: 'App',
  computed: {
    cachedViews () {
      // console.log(this.$store)
      return this.$store.state.cachedViews
    },
    
    key () {
      return this.$route.path
    },
 },
 // 监听路由变化，为keepAlive：true 的路由自动添加到缓存数组
 watch: {
    $route: {
      immediate: true,
      handler (route) {
        const { name, meta: { keepAlive } } = route
        if (name && keepAlive) {
          this.$store.commit('ADD_CACHED_VIEW', route)
        }
      },
    },
  }
}
</script>

<style>
.van-collapse-item__title{
  align-items: center;
}
</style>
