// 上传文件到阿里云OSS
// import axios from 'axios';
import OSS from 'ali-oss';
import axios from 'axios';  // 引入axios
axios.defaults.baseURL = 'http://localhost:8081'; // 设置axios请求的根路径

// const getToken = async () => {
//     // 设置客户端请求访问凭证的地址。
//     await axios.get("/mingguang/get_oss_token/").then((token) => {
//       const client = new OSS({
//          // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
//         region: 'oss-cn-nanjing',
//         accessKeyId: token.data.AccessKeyId,
//         accessKeySecret: token.data.AccessKeySecret,
//         stsToken: token.data.SecurityToken,
//         // 填写Bucket名称。
//         bucket: " zhiyi-science",
//         // 刷新临时访问凭证。
//         refreshSTSToken: async () => {
//           const refreshToken = await axios.get("/mingguang/get_oss_token/");
//           return {
//             accessKeyId: refreshToken.AccessKeyId,
//             accessKeySecret: refreshToken.AccessKeySecret,
//             stsToken: refreshToken.SecurityToken,
//           };
//         },
//       });
//       return client;
      
//     }).catch(e=>console.log(e));
//   };

// export default {
//     // 获取token
//     getToken,
//     // 上传文件
//     // uploadFile,
//     // 删除文件
//     // deleteFile
// }
export const getToken = async () => {
    try {
        const response = await axios.get("/mingguang/get_oss_token/");
        
        const { accessKeyId, accessKeySecret, securityToken } = response.data;
        
        const client = new OSS({
            // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
            region: 'oss-cn-nanjing',
            accessKeyId: accessKeyId,
            accessKeySecret: accessKeySecret,
            stsToken: securityToken,
            // 填写Bucket名称。
            bucket: "zhiyi-science",
            // 刷新临时访问凭证。
            refreshSTSToken: async () => {
                const { data } = await axios.get("/mingguang/get_oss_token/");
                return {
                    accessKeyId: data.accessKeyId,
                    accessKeySecret: data.accessKeySecret,
                    stsToken: data.securityToken,
                };
            },
        });

        return client;
    } catch (error) {
        console.error('Failed to get token:', error);
        throw error; // 抛出错误，让调用者可以捕获
    }
};


