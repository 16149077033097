<template>
  <div>
    <van-nav-bar
      title="商户注册"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- 注册表单 -->
    <div class="register_page">
      <van-form>
        <van-field
          v-model="phone"
          name="phone"
          label="手机号"
          placeholder="手机号"
          
        />

        <van-field
          v-model="sms"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              @click="sendsms"
              :disabled="bu_status"
              >{{ button_name }}</van-button
            >
          </template>
        </van-field>

        <van-field
          v-model="password"
          type="password"
          name="password"
          label="密码"
          placeholder="请填写密码"
          
        />

        <van-field
          v-model="password2"
          type="password"
          name="password2"
          label="确认密码"
          placeholder="请再次输入密码"
          
        />

        <div style="margin: 16px">
          <van-button round block type="info"  @click="onSubmit"
            >注 册</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>



<script>
import md5 from "md5.js";
export default {
  data() {
    return {
      phone: "",
      sms: "",
      password: "",
      password2: "",
      button_name: "发送验证码",
    };
  },
  computed: {
    bu_status() {
      if (this.button_name === "发送验证码") {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    //返回
    onClickLeft() {
      this.$router.back();
    },
    //发送短信
    sendsms() {
      // 先判断手机号格式是否正确
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$toast.fail("手机号格式不正确");
        return;
      }
      //发送请求
      this.$http
        .post("/mingguang/sms_code/", {
          phone_number: this.phone,
        })
        .then((res) => {
          this.$toast.success("发送成功");
          console.log(res);
          //将当前时间存入localStorage
          localStorage.setItem("sms_time", new Date().getTime());
          // 倒计时
          let count = 60;
          const timer = setInterval(() => {
            if (count > 0) {
              this.button_name = count + "s后重发";
              count--;
            } else {
              this.button_name = "发送验证码";
              clearInterval(timer);
            }
          }, 1000);
        })
        .catch((err) => {
          this.$toast.fail(err.message);
        });
    },
    onSubmit() {
      // 校验不能为空
      if (!this.phone || !this.sms || !this.password || !this.password2) {
        this.$toast.fail("请填写完整信息");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$toast.fail("手机号格式不正确");
        return;
      }
      if (this.password !== this.password2) {
        this.$toast.fail("两次密码不一致");
        return;
      }
      // 对密码进行md5加密
      // 创建一个 md5 实例
      const hash = new md5();
      // 更新数据
      hash.update(this.password);
      // 获取十六进制格式的哈希值
      const password_md5 = hash.digest('hex');
      this.$toast.loading({
        message: "注册中...",
        forbidClick: true,
      });
      this.$http
        .post("/mingguang/register/", {
          phone: this.phone,
          sms: this.sms,
          password: password_md5,
        })
        .then((res) => {
          if(res.data.code === 200){
            console.log(res);
            this.$toast.success("注册成功");
            this.$toast.clear();
            this.$router.replace("/login");
          }else{
            this.$toast.fail(res.data.msg);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
  },
  mounted() {
    //判断是否已经发送过短信
    const sms_time = localStorage.getItem("sms_time");
    if (sms_time) {
      //判断是否超过一分钟
      const now = new Date().getTime();
      const diff = now - sms_time;
      if (diff >= 60000) {
        this.button_name = "发送验证码";
      } else {
        // 开始倒计时
        let count = Math.ceil((60000 - diff) / 1000);
        const timer = setInterval(() => {
          if (count > 0) {
            this.button_name = count + "s后重发";
            count--;
          } else {
            this.button_name = "发送验证码";
            clearInterval(timer);
          }
        }, 1000);
      }
    }
  },
};
</script>


<style>
.register_page {
  margin-top: 20px;
}
</style>