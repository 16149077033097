<template>
    <div>
        <van-nav-bar title="商品详情" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="text_content">
            <!-- 标题 -->
            <div class="title">
                {{ title }}
            </div>

            <!-- 内容 -->
            <div class="content">
                {{ content }}
            </div>

            <!-- 标签 -->
            <div class="tag">
                <van-tag v-for="(tag, index) in tags" :key="index" color="#ffe1e1" text-color="#ad0000" :style="{ 'margin-right': '3px' }">
                    {{ tag }}
                </van-tag>
                
            </div>

            <!-- 价格 -->
            <div class="price">
                ￥{{ price }} <span>{{ price_2 }}</span>
            </div>

            <!-- 分类,分组标签 -->
            <div class="tag">
                <van-tag plain type="success" :style="{ 'margin-right': '5px' }">{{ category }}</van-tag>
                <van-tag plain type="primary" :style="{ 'margin-right': '5px' }">{{ group }}</van-tag>
            </div>

        </div>

        <div class="image_area">
            <img :src="pic_url" alt="" v-for="(pic_url,index) in pictures" :key="index" >
        </div>
    </div>
</template>


<script>
export default {
    name: "GoodsDetailPage",
    data() {
        return {
            id: '',
            title: "",
            content: "",
            tags: [],
            price: "",
            price_2: "",
            category: "",
            group: "",
            pictures: []
        }
    },
    methods: {
        onClickLeft() {
            this.$router.back();
        },
    },
    mounted() {
        this.id = this.$route.query.id
        this.$http
            .get("/mingguang/get_goods_detail", {
                params: {
                    id: this.id
                }
            })
            .then((res) => {
                console.log(res);
                if (res.data.code === 200) {
                    this.title = res.data.data.title;
                    this.content = res.data.data.detail;
                    this.price = res.data.data.price;
                    this.tags = res.data.data.tags;
                    this.price_2 = res.data.data.chart_org;
                    this.category = res.data.data.category;
                    this.group = res.data.data.group;
                    this.pictures = res.data.data.pictures;

                }else{
                    this.$toast.fail(res.data.msg);
                }
            }).catch(e => {
                console.log(e);
                this.$toast.fail("服务异常");
            });
    },
    
    
}
</script>
<style scoped >

.text_content{
    margin: 10px;
    padding: 10px;
    
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 5px rgb(212, 211, 211);
}
.title{
    font-size: 16px;
    font-weight: bold;
}
.content{
    margin-top: 10px;
    font-size: 14px;
    color: rgb(128, 128, 128);
}
.tag{
    margin-top: 10px;
}
.price{
    margin-top: 10px;
    font-size: 14px;
    color: rgb(238, 7, 7);
}
.price span{
    text-decoration: line-through;
    color: rgb(128, 128, 128);
    font-size: 12px;
}
.image_area{
    /* margin-top: 10px; */
    font-size: 0;
    /* padding-left: 10px;
    padding-right: 10px; */
}
.image_area img{
    width: 100%;
    height: auto;
}
</style>