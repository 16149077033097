//vuex
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

//state
const state = {
	//用户信息
	userInfo: {},
	//token
	token: '',
	cachedViews: [],

}
//actions
const actions = {
	//设置用户信息
	setUserInfo(context, userInfo) {
		context.commit('setUserInfo', userInfo)
	},
	//设置token
	setToken(context, token) {
		context.commit('setToken', token)
	},
	//设置是否登录
	setIsLogin(context, isLogin) {
		context.commit('setIsLogin', isLogin)
	},
}
//mutations
const mutations = {
	//设置用户信息
	setUserInfo(state, userInfo) {
		state.userInfo = userInfo
	},
	//设置token
	setToken(state, token) {
		state.token = token
	},
	//设置是否登录
	setIsLogin(state, isLogin) {
		state.isLogin = isLogin
	},

	// 添加缓存
	ADD_CACHED_VIEW: (state, view) => {
		if (state.cachedViews.includes(view.name)) {
			return
		}
		state.cachedViews.push(view.name)
	},
	// 移除缓存
	DEL_CACHED_VIEW: (state, view) => {
		const index = state.cachedViews.indexOf(view.name)
		index > -1 && state.cachedViews.splice(index, 1)
	},

}
export default new Vuex.Store({
	state,
	mutations,
	actions
})
