<template>
  <div>
    <van-nav-bar
      title="回复列表"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div
        v-for="(item, index) in list"
        :key="index"
        @click="onClick(item)"
        class="item"
      >
        <div class="img_text_1">
          <div class="img">
            <img :src="item.url" alt="" />
          </div>

          <div class="text">
            <p>{{ item.title }}</p>
            <p>￥{{ item.price }}</p>
            <p>{{ item.time }}</p>
          </div>
        </div>
        <div class="num">
          <p>
            {{ item.anw }}<span> / {{ item.no_anw }}</span>
          </p>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: '',
      list: [],
      loading: false,
      finished: false,
      page: 1,
    };
  },
  methods: {
    onLoad() {
      console.log("onLoad被调用了");
      // 发起请求获取数据
      this.$http
        .get("/mingguang/get_reply_list", {
          params: {
            page: this.page,
            s_id: this.id
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            //判断是否还有数据
          if (res.data.data.length < 15) {
            this.list = [...this.list, ...res.data.data]
            this.loading = false
            this.finished = true
          } else {
            this.list.push(res.data.data)
            this.loading = false
            this.page++
          }
          }else{
            this.$toast.fail("服务异常")
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onClickLeft() {
      this.$router.back()
    },
    onClick(item) {
      this.$router.push({
        path: "/answerdetail",
        query: {
          id: item.id,
          title: item.title,
          url: item.url,
          price: item.price,
          time: item.time,
        },
      });
    },
  },
  mounted() {
    
    this.id = this.$route.query.id;
  },
};
</script>

<style>
.item {
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding: 5px;
}
.img_text_1 {
    display: flex;
    align-items: center;
}
.img {
  width: 100px;
  height: 100px;
  
}
.text {
  margin-left: 10px;
  width: 200px;
  
  
}
.text p:first-child {
  font-weight: bold;
  font-size: 16px;
  /* 超出显示省略号 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}
.text p:nth-child(2) {
    color: red;
}
.text p:nth-child(3) {
    color: rgb(128, 128, 128);
    font-size: small;
}
.num {
    font-size: small;
    color: coral;
    font-weight: 700;
}
.num span {
    color: rgb(128, 128, 128);
    font-size: small;
    font-weight: 400;
}
.img img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  
}
</style>