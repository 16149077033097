<template>
    <div>
    <van-nav-bar title="修改门店" left-text="返回" left-arrow @click-left="onClickLeft" />
    <!-- 表单 -->
    <div class="add_shop_page">
      <van-form>
        <van-field v-model="name" name="name" label="门店名称" placeholder="门店名称"  />
        <van-field v-model="person" name="person" label="负责人姓名" placeholder="负责人姓名"  />
        <van-field v-model="phone" name="phone" label="负责人手机" placeholder="负责人手机"  />
        <van-field v-model="email" name="email" label="负责人邮箱" placeholder="负责人邮箱"  />

        <van-field v-model="address" name="address" label="门店地址" placeholder="门店地址" />

        <div class="custom_text">门头照</div>
        <div class="custom_pic">
            <van-uploader v-model="fileList1" :after-read="afterRead1" :max-count="1"/>
        </div>

        <div class="custom_text">营业执照</div>
        <div class="custom_pic">
            <van-uploader v-model="fileList2" :after-read="afterRead2" :max-count="1"/>
        </div>
        

        <div style="margin: 16px;">
          <van-button round block type="info" @click="onSubmit">提 交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>


<script>
import { getToken } from "../utils/tools.js";
export default {
    data() {
        return {
            name: '',
            person: '',
            email: '',
            address: '',
            phone: '',
            fileList1: [],
            fileList2: []
        }
    },
    methods: {
        onSubmit() {
            //判断数据不为空
            if (this.name === '' || this.person === '' || this.email === '' || this.address === '' || this.phone === '' || this.fileList1.length === 0 || this.fileList2.length === 0) {
                this.$toast.fail("请填写完整信息");
                return;
            }
            // 校验手机号和邮箱格式
            if (!/^1[3456789]\d{9}$/.test(this.phone)) {
                this.$toast.fail("请输入正确的手机号");
                return;
            }
            if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.email)) {
                this.$toast.fail("请输入正确的邮箱");
                return;
            }
            // 发送请求
            this.$http
                .post("/mingguang/edit_shop/", {
                    id: this.$route.query.id,
                    name: this.name,
                    person: this.person,
                    email: this.email,
                    address: this.address,
                    phone: this.phone,
                    pic: this.fileList1[0].url,
                    license: this.fileList2[0].url
                })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.$toast.success("修改成功");
                        this.$router.replace({
                            path: "/shoplist"
                        })
                    } else {
                        this.$toast.fail(res.data.msg);
                    }
                }).catch(e => {
                    console.log(e)
                    this.$toast.fail("服务异常");
                });
        },
        onClickLeft() {
            this.$router.push({
                path: "/shoplist"
            })
        },
        upload_image(file, fileList) {
            this.$set(fileList[0], 'status', 'uploading')
            this.$set(fileList[0], 'message', '上传中...')
            // 此时可以自行将文件上传至服务器
            //获取上传对象
            // 使用临时访问凭证上传文件。
            // 填写不包含Bucket名称在内的Object的完整路径,文件夹 + 时间戳 + 文件名
            // 填写本地文件buffer。
            // app.js
            getToken()
                .then(client => {
                    console.log('OSS Client:', client);
                    // 使用 client 进行其他操作
                    const filePath = this.$filePath + Date.now() + "_" + file.file.name
                    client.put(filePath, file.file)
                        .then((res) => {
                            console.log(res)
                            //上传成功,更新fileList
                            this.$set(fileList[0], 'status', 'done')
                            this.$set(fileList[0], 'url', res.url)
                            this.$set(fileList[0], 'message', '')
                            console.log(this.fileList1)

                        }).catch(e => {
                            // 上传失败更新fileList1
                            console.log(e)
                            this.$set(fileList[0], 'status', 'failed')
                            this.$set(fileList[0], 'message', '上传失败')
                        })
                })
                .catch(error => {
                    console.error('Error getting token:', error);
                });

        },
        afterRead1(file) {
            this.upload_image(file, this.fileList1)
        },
        afterRead2(file) {
            this.upload_image(file, this.fileList2)
        },
        delete_image1() {
            console.log(this.fileList1)
        }
    },
    mounted() {
        this.$http
            .get("/mingguang/get_shop_info", {
                params: {
                    id: this.$route.query.id
                }
            })
            .then((res) => {
                if (res.data.code === 200) {
                    this.name = res.data.data.name
                    this.person = res.data.data.person
                    this.email = res.data.data.email
                    this.address = res.data.data.address
                    this.phone = res.data.data.phone
                    this.fileList1 = res.data.data.pic1
                    this.fileList2 = res.data.data.pic2
                }
            })
    }
    
}


</script>
<style>
.custom_text{
    font-size: 14px;
    color: #646566;
    margin-left: 15px;
    margin-top: 20px;
}
.custom_pic{
    margin-left: 15px;
    margin-top: 20px;
}
</style>