<template>
  <div>
    <van-collapse v-model="activeNames" accordion>
      <van-collapse-item
        v-for="(item, index) in messages"
        :name="index"
        :key="item.id"
      >
        <!-- 自定义标题 -->
        <template #title>
          <div class="p_info">
            <img :src="item.url" alt="" />
            <div class="p_name">
              {{ item.name }}
            </div>
          </div>
        </template>
        <!-- 内容 -->
        <div class="message_content">
          <div class="message_question">
            <div><span class="question">问</span>: {{ item.message }}</div>
            <div class="time">{{ item.message_time }}</div>
          </div>
          <div class="message_answer" v-if="item.answer != null">
            <div><span class="question">答</span>: {{ item.answer }}</div>
            <div class="time">{{ item.answer_time }}</div>
          </div>
          <div v-else>
            <van-button type="primary" size="mini" @click="showInput(item.id)"
              >回复</van-button
            >
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>

    <van-field
      v-model="value"
      placeholder="请输入回复内容"
      v-show="show_input"
      ref="anw"
    />
  </div>
</template>
<script>
export default {
  name: "MessageItem",
  data() {
    return {
      activeNames: ["1"],
      value: "",
      show_input: false,
      messages: [
        // {
        //   id: 1,
        //   url: "https://img1.baidu.com/it/u=3285655239,3777030402&fm=253&fmt=auto&app=120&f=JPEG?w=507&h=500",
        //   name: "小猫",
        //   message: "你好啊",
        //   message_time: "01-01 01:01:01",
        //   answer: "你好啊你好啊你好啊",
        //   answer_time: "01-02 15:01:01",
        // },
      ],
      messages_wait: [
        // {
        //   id: 1,
        //   url: "https://img1.baidu.com/it/u=3285655239,3777030402&fm=253&fmt=auto&app=120&f=JPEG?w=507&h=500",
        //   name: "小猫",
        //   message: "你好啊",
        // },
      ],
    };
  },
  methods: {
    showInput(id) {
      var reason = prompt("回复内容:");
      if (reason) {
        //发起请求
        this.$http
          .post("/mingguang/reply_message/", {
            m_id: id,
            answer: reason,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code === 200) {
              this.$toast.success("回复成功");
              this.messages.forEach((item) => {
                if (item.id === id) {
                  item.answer = reason;
                  item.answer_time = res.data.answer_time;
                }
              });
            } else {
              this.$toast.fail(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast.fail("服务异常");
          });
      } else {
        this.$toast.fail("内容为空");
      }
    },
  },
  props: {
    goods_id: {
      type: String,
      required: true,
    },
    tab_type: {
      type: String,
      required: true,
    },
  },
  mounted() {
    //发起请求
    console.log(this.tab_type);
    console.log(this.goods_id)
    this.$http
      .get("/mingguang/get_reply_detail", {
        params: {
          goods_id: this.goods_id,
          tab_type: this.tab_type,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.messages = res.data.data;
        } else {
          this.$toast.fail(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
        this.$toast.fail("服务异常");
      });
  },
};
</script>
<style scoped>
.p_info {
  display: flex;
  align-items: center;
}
.p_name {
  margin-left: 10px;
}
.p_info img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}
.message_content {
  color: #807d7d;
}
.message_answer {
  margin-top: 6px;
}
.question {
  color: #000;
}
.time {
  margin-top: 5px;
  color: #807d7d;
  font-size: 12px;
}
</style>